<template>
  <wiskInputGroup @errorCountChanged="setValidState" class="px-0 fill-width-height invite-to-venues-selected-rows-action-container text-center"
    fieldsetClass="bg-white" :legend="translations.txtSubrecipesCopyToVenues" :disabled="disabled" :key="key" v-if="availableVenues.length">

    <wiskSelect infoTooltipKey="347c242d-4205-4bdc-8b9c-30a982ff6373" :label="translations.txtGenericVenues" v-model="selectedVenueIds" :items="availableVenues" multiple
      class="mb-3" required forceAllowEmpty fixedWrapper />

    <b-button size="sm" variant="link" @click="submit" class="mx-auto text-primary" v-tooltip="translations.txtGenericSubmit" :disabled="!valid">
      <icon class="ms-1 d-inline" style="margin-top: -1px;" scale="1.4" name="wisk-check" />
    </b-button>

  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'

export default {
  name: 'SubrecipesCopyToVenues',
  emits: ['submit'],
  components: {},
  props: {
    rows: { type: Array, required: true },
    clearAfterEmitOperation: Boolean,
    checkDisabled: Function,
  },
  data() {
    return {
      key: 1,
      valid: true,
      disabled: false,
      selectedVenueIds: [],
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'user', 'currentPermissionsByType']),
    ...mapGetters(['permissionsByVenueIdByType', 'siblingVenues']),
    availableVenues() {
      return this.siblingVenues.filter(v => this.getPermissionsByVenueIdByType(v.id).batch_manage)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    getPermissionsByVenueIdByType(venueId) {
      return get(this.user, 'god_mode') ? this.currentPermissionsByType || {} : (this.permissionsByVenueIdByType || {})[venueId] || {}
    },
    submit() {
      if (this.valid && this.selectedVenueIds.length) {
        this.setGlobalAction({
          type: 'confirmDialog',
          action: {
            type: 'confirm',
            payload: {
              callback: () => {
                this.$emit('submit', { to_venue_ids: this.selectedVenueIds, subrecipe_ids: this.rows.map(row => row.id) })

                if (this.clearAfterEmitOperation) {
                  this.selectedVenueIds = []
                  this.key++
                }
              },
              title: this.translations.txtSubrecipesCopyToVenues,
              message: this.translations.txtSubrecipesCopyToVenuesConfirmMessage
            }
          }
        })
      }
    }
  },
  mounted() {
    if (this.checkDisabled) {
      this.disabled = this.checkDisabled(this.rows)
    }
  },
  watch: {
    disabled() {
      this.key++
    }
  }
}
</script>

<style lang="scss"></style>
