<template>
  <div>
    <wiskModal :visible="!!(editAction?.id && editAction?.successMode)" @hide="setGlobalAction({ type: 'movementEdit', action: null })"
      :title="modalTitle" size="lg" hideOK>
      <b-row class="mb-3">
        <b-col>
          {{ translations.txtMovementEditConvertedSuccessMessage }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="me-2" variant="primary" @click="openEditMode">
            {{ translations.txtMovementEditViewIntake }}
          </b-button>
        </b-col>
      </b-row>
    </wiskModal>

    <wiskModal :warning="movement?.archived" v-model="imageViewerVisible" :title="`${modalTitle} - ${translations.txtGenericPhotos}`" size="lg" hideFooter extraLarge>
      <b-row>
        <b-col style="min-height: 600px;">
          <imagePicker v-if="imageViewerVisible" @delete="removeImage" promptAddIfEmpty preSelectFirst canDelete :addImage="addImages" viewOnly :images="attachments" />
        </b-col>
      </b-row>
    </wiskModal>

    <wiskModal :warning="movement?.archived" :visible="!!editAction && !editAction.successMode" @hide="setGlobalAction({ type: 'movementEdit', action: null })"
      :size="!!movement?.id ? 'lg' : 'md'" extraLarge :fullHeight="!!movement?.id" :hideFooter="!!movement?.id"
      :fullScreen="imagesSideBySide" :id="imagesSideBySide ? 'movement-edit-container-image-side-by-side' : 'movement-edit-container'" :hideHeaderExtra="(movement && !movement.id)">

      <template v-slot:modal-header>
        <b-container fluid>
          <b-row noGutters>
            <b-col class="d-flex flex-column">
                <div class="d-flex align-items-center" :title="isLocked ? translations.txtMovementLockedDescription : ''">
                  <h5 class="modal-title me-2 float-start" style=""> {{ modalTitle }} </h5>
                  <icon v-if="isLocked" name="wisk-lock" class="text-primary" :scale="1" />
                </div>
                <p v-if="movement?.sent_to_accounting" class="mb-0 text-primary">
                  {{ movement.modified_after_sent_to_accounting ? translations.txtMovementAccountingSentChanged : translations.txtMovementAccountingSentNotEditable }}
                </p>
              </b-col>
            <b-col cols="12" md="8" class="px-0-m">
              <gateKeeper v-if="movement?.id" feature="deliveries" class="w-100 h-100" containerClass="d-block" smallBlock>
                <b-form-radio-group class="float-start mb-2-m" buttons v-model="selectedEditorTab" button-variant="outline-primary" v-if="!imagesSideBySide">
                  <b-form-radio class="py-1" :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
                  <b-form-radio class="py-1" :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
                  <b-form-radio class="py-1" :value="2" v-if="venue.accounting_enabled"> {{ translations.txtGLAccounts }} </b-form-radio>
                  <b-form-radio class="py-1" :value="3"> {{ translations.txtGenericCostChanges }} ({{ costChangesCount }})</b-form-radio>
                </b-form-radio-group>

                <wiskActions :title="translations.txtGenericActions" v-if="movement?.id" :actions="pageActions" size="sm" />

                <b-button class="float-end py-1 mx-2" v-if="isTransferOut" :disabled="disabled" @click="sendTransferOut">
                  {{ movement.sent ? translations.txtMovementTransferOutSentAlready : translations.txtGenericSendTransferOut }}
                </b-button>

                <b-dropdown right v-if="movement.attachments.length && !imagesSideBySide" variant="primary" :text="translations.txtGenericImages" class="float-end me-2" size="sm"
                  :disabled="!movement.attachments.length" id="movement-photos-dropdown" :style="{ 'max-height': imagesSideBySide ? 'calc(100vh - 90px)' : '' }">
                  <b-dropdown-item @click="imageViewerVisible = true">
                    {{ translations.txtMovementEditViewInvoiceImages }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="imagesSideBySide = true" class="d-none d-md-block">
                    {{ translations.txtMovementEditViewInvoiceImagesSideBySide }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="emailPdf">
                    {{ translations.txtMovementEditEmailPDF }}
                  </b-dropdown-item>
                </b-dropdown>

                <b-button v-if="imagesSideBySide" @click="imagesSideBySide = false">
                  {{ translations.txtMovementEditViewInvoiceImagesSideBySideExit }}
                </b-button>

                <b-button size="sm" class="float-end me-2" v-if="!movement.archived && !movement.attachments.length && !imagesSideBySide" @click="imageViewerVisible = true" variant="primary">
                  {{ translations.txtGenericImagesPDFAdd }}
                </b-button>
                </gateKeeper>
            </b-col>
          </b-row>

          <button @click="setGlobalAction({ type: 'movementEdit', action: null })" aria-label="Close" class="close btn-close position-absolute">
            <icon class="text-secondary" name="wisk-exit" :scale="0.64" />
          </button>
        </b-container>
      </template>
      <gateKeeper feature="deliveries" class="">
        <div v-if="editAction && !editAction.id" style="height: 500px;" class="w-100">
          <wiskInputGroup @errorCountChanged="setValidState" v-if="isIntake">
            <wiskSelect infoTooltipKey="27c3a8c6-b566-4122-a6b5-908a9ddc8583" :label="translations.txtPurchaseOrdersSelectDistributor" trackBy="id" :items="activeDistributors" required
              @update:modelValue="newMovement.distributor_id = $event" :addNewItem="addDistributorConfig" />

            <wiskSelect infoTooltipKey="a46bba1f-23c9-4d54-9480-583f95f14ae2" :label="translations.txtGenericLocation" trackBy="id" :items="activeLocations" required
              @update:modelValue="newMovement.to = { id: $event, title: locationsById[$event].title }" :modelValue="newMovement.to" />

            <wiskInput infoTooltipKey="e5b2e6b1-e70b-4f12-9a8d-c9951fa136fa" @update:modelValue="newMovement.invoice_number = $event" :label="translations.txtMovementEditInvoiceNumber" />

            <wiskInput infoTooltipKey="ae0901d1-13d0-4b00-beaf-9575943c92f7" @update:modelValue="newMovement.invoice_total = $event"
              :label="translations.txtMovementEditInvoiceTotalPaper" inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" />

            <wiskInput infoTooltipKey="04153d43-02dd-4d0f-a2e1-4e964b8734a5" @update:modelValue="newMovement.date = $event" :label="translations.txtGenericDate" inputType="datepicker" :modelValue="newMovement.date"
              :min="venue.last_locked_inventory_date" :max="new Date()"/>

            <b-button size="sm" class="" @click="addImagesToNewMovement" variant="primary" v-if="!newMovement.attachments">
              {{ translations.txtGenericImagesPDFAdd }}
            </b-button>

            <b-container v-if="newMovement.attachments" fluid class="p-2 images-preview">
              <b-row>
                <b-col cols="2" v-for="(image, index) in newMovement.attachments" :key="index" class="">
                  <imageView :src="image" filterDisplay="mdSmall" class="" />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-3 me-2">
                  <b-button size="sm" class="" @click="newMovement.attachments = null" variant="danger" v-if="newMovement.attachments">
                    {{ translations.txtGenericImagesClear }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </wiskInputGroup>

          <wiskInputGroup @errorCountChanged="setValidState" v-if="isReturn || isTransferOut">
            <wiskSelect v-if="isReturn" infoTooltipKey="88d3482b-4a5d-4c15-8c8f-027b76ea25b4" :label="translations.txtPurchaseOrdersSelectDistributor" trackBy="id" :items="activeDistributors" required
              @update:modelValue="newMovement.distributor_id = $event" :addNewItem="addDistributorConfig" />

            <wiskSelect v-if="isTransferOut" infoTooltipKey="8cfc3bdc-2ff9-4b50-a3c0-906555e93e4b" :label="translations.txtMovementEditPartnerVenue" trackBy="id" :items="linkedDistributors" required
              @update:modelValue="newMovement.distributor_id = $event" />

            <wiskSelect infoTooltipKey="f6baab77-cb22-4824-8ace-206c30a88710" :label="translations.txtGenericLocation" trackBy="id" :items="activeLocations" required
              @update:modelValue="newMovement.from = { id: $event, title: locationsById[$event].title }" :modelValue="newMovement.from" />
          </wiskInputGroup>

          <wiskInputGroup @errorCountChanged="setValidState" v-if="isTransfer">
            <wiskSelect infoTooltipKey="243f368b-d41a-4e59-a433-80d4e59a9f31" :label="translations.txtMovementEditLocationFrom" trackBy="id" :items="activeLocations" required
              @update:modelValue="newMovement.from = { id: $event, title: locationsById[$event].title }" />

            <wiskSelect infoTooltipKey="5fff6471-1eaf-4638-9492-066f28217cd0" :label="translations.txtMovementEditLocationTo" trackBy="id" :items="activeLocations" required
              @update:modelValue="newMovement.to = { id: $event, title: locationsById[$event].title }" />
          </wiskInputGroup>

          <wiskInputGroup @errorCountChanged="setValidState" v-if="isAdjustment">
            <wiskSelect infoTooltipKey="223171c7-b1c1-4cb2-9214-2992ef23d76a" :label="translations.txtGenericLocation" trackBy="id" :items="activeLocations" required
              @update:modelValue="newMovement.at = { id: $event, title: locationsById[$event].title }" />

            <wiskSelect infoTooltipKey="bb46d2a9-4047-4f31-a008-ce230d7c4a66" :label="translations.txtMovementDepletionReason" trackBy="id" :items="movementAdjustmentReasons" required
              @update:modelValue="onAdjustmentReasonSelected" :addNewItem="addNewReason" :modelValue="newMovement.adjustment_reason_id" />

          </wiskInputGroup>
        </div>

        <div v-if="movement?.id" class="min-h-440 w-100">
          <div v-if="selectedEditorTab === 0">
            <b-row :key="key">
              <b-col v-if="imagesSideBySide">
                <imagePicker v-if="!movement.archived" :addImage="addImages" :images="attachments" viewOnly @delete="removeImage" canDelete preSelectFirst />
              </b-col>
              <b-col :style="{ 'padding-left': imagesSideBySide ? '0' : '', 'max-height': imagesSideBySide ? 'calc(100vh - 110px)' : '', 'overflow-y': imagesSideBySide ? 'auto' : '' }">
                <b-container fluid>
                  <wiskInputGroup :disabled="disabled">
                    <b-row class="pt-2">
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1">
                        <wiskInput infoTooltipKey="9cc72a1f-0230-42fd-aab8-b3294d6140c0" :label="translations.txtGenericDate" :modelValue="movement.date" inputType="datepicker" operation="date" @operation="save"
                          required :min="venue.last_locked_inventory_date" :max="new Date()"/>
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isReturn || isIntake">
                        <wiskSelect infoTooltipKey="ba7001ba-874c-46c8-906e-c4be21d6f948" :label="translations.txtGenericDistributor" :modelValue="distributor" trackBy="id"
                          :items="activeDistributors" required operation="distributor_id" @operation="save" :addNewItem="addDistributorConfig" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isAdjustment">
                        <wiskSelect infoTooltipKey="cfc2a23b-18cf-4c45-b9ba-9b86487569a6" :label="translations.txtGenericLocation" :modelValue="getLocation(operationOperation.at)"
                          @update:modelValue="operationOperation.at = { id: $event, title: locationsById[$event].title }; save({ type: 'operation', value: operationOperation })" trackBy="id" :items="activeLocations" required />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isAdjustment">
                        <wiskSelect infoTooltipKey="8997a333-3814-4e06-a93a-540c8dbb7999" :label="translations.txtMovementDepletionReason" trackBy="id" :items="movementAdjustmentReasons" required
                          @update:modelValue="onAdjustmentReasonSelected" :addNewItem="addNewReason" :modelValue="operationOperation.adjustment_reason_id" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isReturn || isTransferOut">
                        <wiskSelect infoTooltipKey="f359d534-56f0-4396-b604-688d5425afc8" :label="translations.txtGenericLocation" :modelValue="getLocation(operationOperation.from)"
                          @update:modelValue=" operationOperation.from = { id: $event, title: locationsById[$event].title }; save({ type: 'operation', value: operationOperation })" trackBy="id" :items="activeLocations" required />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isIntake">
                        <wiskSelect infoTooltipKey="ceee2897-1677-4336-a0b1-f04248c973cb" :label="translations.txtGenericLocation" :modelValue="getLocation(operationOperation.to)"
                          @update:modelValue=" operationOperation.to = { id: $event, title: locationsById[$event].title }; save({ type: 'operation', value: operationOperation })" trackBy="id" :items="activeLocations" required />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isTransfer">
                        <wiskSelect infoTooltipKey="c777bd3b-36f1-4052-b65e-7cdcd64fdfbb" :label="translations.txtMovementEditLocationFrom" :modelValue="getLocation(operationOperation.from)"
                          @update:modelValue=" operationOperation.from = { id: $event, title: locationsById[$event].title }; save({ type: 'operation', value: operationOperation })" trackBy="id" :items="activeLocations" required />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isTransfer">
                        <wiskSelect infoTooltipKey="a70ba7b3-76ce-43b5-9988-76fc357dedc4" :label="translations.txtMovementEditLocationTo" :modelValue="getLocation(operationOperation.to)"
                          @update:modelValue=" operationOperation.to = { id: $event, title: locationsById[$event].title }; save({ type: 'operation', value: operationOperation })" trackBy="id" :items="activeLocations" required />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isIntake">
                        <wiskInput infoTooltipKey="90012314-4859-41a7-a9d2-2916ec578433" operation="operationOperation" @operation=" operationOperation.invoice_number = $event.value; saveDebounced({ type: 'operation', value: operationOperation })"
                          :label="translations.txtMovementEditInvoiceNumber" :modelValue="operationOperation.invoice_number" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isIntake && currentPermissionsByType.tax_rate_assign && venue.taxes_enabled">
                        <wiskSelect infoTooltipKey="b0ad730b-e680-42ec-bd71-c30cc49d2aae" :label="translations.txtTaxesScheme" trackBy="id" :items="taxSchemes" required :customLabelOverride="taxSchemeSelectorLabelTranslator"
                          @operation="saveDebounced" :modelValue="movement.taxes_scheme" operation="taxes_scheme" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isIntake">
                        <wiskInput infoTooltipKey="c3a15b27-f212-40b3-a890-371cfe47d43e" operation="operationOperation" @operation="console.log($event); operationOperation.invoice_total = $event.value; saveDebounced({ type: 'operation', value: operationOperation })"
                          :label="translations.txtMovementEditInvoiceTotalPaper" inputType="number" :decimals="2" :modelValue="operationOperation.invoice_total"
                          :prefix="currency" :validations="[validations.totalsMatch]" ref="refInvoiceTotalComponent" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1">
                        <wiskInput infoTooltipKey="369bae6f-5ae6-4f2e-9874-09c96601f0f7" disabled :label="translations.txtGenericTotal" inputType="number" :decimals="2" :modelValue="computedTotal" :prefix="currency" />
                      </b-col>
                      <b-col :lg="imagesSideBySide ? 4 : 3" md="6" cols="12" class="px-1" v-if="isTransferOut">
                        <wiskSelect :label="translations.txtMovementEditPartnerVenue" :modelValue="distributor" trackBy="id" :items="activeDistributors" required operation="destination_id"
                          @operation="save" :addNewItem="addDistributorConfig" />
                      </b-col>
                    </b-row>
                  </wiskInputGroup>
                  <wiskItemsGrid class="p-0 mb-3" :gridStyle="{}" gridAutoHeight :columns="itemsGridColumns" :items="items" :header="{}"
                    parentGridName="MovementItems" @gridApi="gridReady" :itemIdFilter="editAction.itemIdFilter" :excel="{ fileName: 'MovementItems', header: excelExportHeader }"
                    trackBy="combined_id" ref="wiskItemsGrid" :defaultFilter="{ predicate: () => 1 }" bottomAggregationRow
                    :additionalHeaderCols="7" :additionalHeaderColsMobile="10" :options="gridOptions" :preSelectedView="isAdjustment ? '5d64f4ae-1e04-41fb-82da-bff674482be4' : '9fd3102a-e4fd-48a1-accf-f2f0705a8941'">

                    <template v-slot:additional-controls>
                      <b-button v-if="movement.invoice_source && movement.invoice_source.id && movement.invoice_source.type === 'purchase_order'" size="sm" variant="primary"
                        @click=" setGlobalAction({ type: 'purchaseOrderEdit', action: { id: movement.invoice_source.id }, resetBeforeOpen: true })">
                        {{ translations.txtPurchaseOrdersViewOrder }}
                      </b-button>
                      <b-button v-else-if="isIntake && movement.purchase_order" size="sm" variant="primary"
                        @click=" setGlobalAction({ type: 'purchaseOrderEdit', action: { id: movement.purchase_order.id }, resetBeforeOpen: true })">
                        {{ translations.txtPurchaseOrdersViewOrder }}
                      </b-button>
                      <b-button v-if="movement.invoice_source && movement.invoice_source.id && movement.invoice_source.type === 'scanned_invoice'" size="sm" variant="primary"
                        @click=" setGlobalAction({ type: 'draftInvoiceEdit', action: { id: movement.invoice_source.id }, resetBeforeOpen: true })">
                        {{ translations.txtScannedInvoicesView }}
                      </b-button>
                    </template>
                  </wiskItemsGrid>

                  <movementExtraLines :disabled="disabled" v-if="movement && movement.extra_lines && movement.extra_lines.length" :movement="movement" @save="save" class="mb-3" />

                  <b-row v-if="isAdjustment">
                    <b-col class="">
                      {{ translations.txtMovementItemWarningRemoveFromStock }}
                    </b-col>
                  </b-row>

                  <fieldset v-if="!isLocked">
                    <legend style="border: none;" class="p-0">
                      <b-form-radio-group class="" buttons v-model="addType" button-variant="outline-primary" size="sm">
                        <b-form-radio value="item"> {{ translations.txtMovementItemAdd }} </b-form-radio>
                        <b-form-radio v-if="isAdjustment" value="pos_item_subrecipe"> {{ translations.txtMovementPOSItemSubrecipeAdd }} </b-form-radio>
                        <b-form-radio v-if="!isAdjustment" value="charge"> {{ translations.txtMovementExtraLineAddCharge }} </b-form-radio>
                        <b-form-radio v-if="!isAdjustment" value="credit"> {{ translations.txtMovementExtraLineAddCredit }} </b-form-radio>
                      </b-form-radio-group>
                    </legend>

                    <movementItemAdd v-if="addType === 'item' && !movement.archived" :movementId="movement.id" :disabled="disabled" @add="save" :movementType="movementType" :distributor="distributor" />

                    <movementPOSItemSubrecipeAdd v-if="addType === 'pos_item_subrecipe'" :disabled="disabled" @add="save" />

                    <movementExtraLineAdd v-if="addType === 'charge'" :disabled="disabled" @add="save" type="charge" :key="1001" />

                    <movementExtraLineAdd v-if="addType === 'credit'" :disabled="disabled" @add="save" type="credit" :key="1002" />

                    <div id="movement-add-item-scroll-target"></div>

                  </fieldset>

                  <customFields target="movement" :item="movement" @operation="save" />

                </b-container>
              </b-col>
            </b-row>
          </div>
          <div v-if="selectedEditorTab === 1">
            <timeline parentGridName="MovementEditTimeline" :filters="[{ type: 'movement', id: movement.id }]" />
          </div>
          <div v-if="selectedEditorTab === 2">
            <glAccountsGrid :movement="movement" :gridStyle="{ height: 'calc(100vh - 180px)' }" />
          </div>
          <div v-if="selectedEditorTab === 3">
            <costChangesGrid :movementId="movement.id" :gridStyle="{ height: 'calc(100vh - 180px)' }" parentGridName="CostChangesGridByMovement" />
          </div>
        </div>

        <div v-else style="min-height: 400px;" />

        <wiskLoading :loading="loading" />
      </gateKeeper>

      <template v-slot:modal-footer>
        <editorButtons :editorValid="addMovementValid" :previous="editAction && editAction.previous" :next="editAction && editAction.next"
          :save="(editAction && !editAction.id && saveNew) || undefined" :key="key" />
      </template>

      <measurement v-if="selectedLineMeasurement" :modelValue="selectedLineMeasurement" @update:modelValue="onMeasurementChange" startOpened @hide=" selectedLineMeasurement = null; selectedLine = null" disableTypeChange
        :preferredType="selectedLineMeasurement.item_measurement_type" :extraUnitsOfMeasurement="extraUnitsOfMeasurement" inputClass="d-none" />

      <priceEditor v-if="selectedLinePrice" :itemVariantId="selectedLine.item_distributor_id" v-model="selectedLinePrice" style="" :discountAvailable="movementType === 'intake'" startOpened
        @hide=" selectedLinePrice = null; selectedLine = null" @update:modelValue="onPriceChange" inputClass="d-none" />
    </wiskModal>
    <confirm ref="confirmDialog" autofocus />
    <confirm ref="secondConfirmDialog" autofocus />

    <wiskModal :visible="linkPurchaseOrderModal" size="lg" hideOK
      :title="translations.txtMovementLinkToPurchaseOrder" @hide="linkPurchaseOrderModal = false">
      <wiskGrid :rowData="distributorPurchaseOrders" :gridStyle="{ height: '500px' }"
        :columnDefs="purchaseOrderColumns" parentGridName="LinkPurchaseOrdersGrid" />
    </wiskModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Noty from 'noty'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import { formatDate, valuesToObjectById, round, compareNumbers, prepareVariantIdAsItem, arrayToObjectById } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import movementItemAdd from '@/components/movements/MovementItemAdd'
import movementExtraLineAdd from '@/components/movements/MovementExtraLineAdd'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import timeline from '@/components/timeline/Timeline'
import imagePicker from '@/components/common/ImagePicker'
import measurement from '@/components/bottles/Measurement'
import priceEditor from '@/components/bottles/ItemPrice'
import imageView from '@/components/common/ImageView'
import customFields from '@/components/customFields/CustomFieldsRender'
import movementExtraLines from '@/components/movements/MovementExtraLines'
import movementPOSItemSubrecipeAdd from '@/components/movements/MovementPOSItemSubrecipeAdd'
import glAccountsGrid from '@/components/movements/InvoiceGLAccountsGrid'
import costChangesGrid from '@/components/costChanges/CostChangesGrid'
import { getGridColumns as getPurchaseOrdersColumns } from '@/components/orders/gridOptions'
import { getItemsGridColumns } from './gridOptions'

const newMovement = {
  distributor_id: 0,
  to: null,
  from: null,
  at: null,
  attachments: null,
  type: '',
  date: new Date(),
  invoice_number: '',
  invoice_total: 0
}

export default {
  name: 'MovementEdit',
  components: {
    editorButtons,
    customFields,
    imageView,
    movementItemAdd,
    movementExtraLineAdd,
    movementPOSItemSubrecipeAdd,
    wiskItemsGrid,
    imagePicker,
    priceEditor,
    glAccountsGrid,
    timeline,
    measurement,
    movementExtraLines,
    costChangesGrid
  },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      gridApi: null,
      operations: [],
      attachments: [],
      loading: false,
      selectedEditorTab: 0,
      imageViewerVisible: false,
      selectedExtraLine: null,
      addMovementValid: false,
      newMovement: { ...newMovement },
      operationOperation: { ...newMovement },
      movement: null,
      movementCopy: null,
      selectedLineMeasurement: null,
      selectedLinePrice: null,
      selectedLine: null,
      items: [],
      addType: 'item', //adjustment
      changed: false,
      initDone: false,
      saveDebounceTimeoutId: null,
      imagesSideBySide: false,
      key: 1,
      linkPurchaseOrderModal: false
    }
  },
  computed: {
    ...mapState(['user', 'orders', 'locations', 'glAccountsById', 'bottlesById', 'distributorsById', 'translations', 'customFieldsByTarget', 'currentPermissionsByType', 'locationsById', 'taxesById', 'accountingVenueClasses', 'movementAdjustmentReasons', 'posItemsById', 'subrecipesById']),
    ...mapGetters(['activeDistributors', 'venue', 'activeGLAccounts', 'taxSchemes', 'currency', 'measurementsByType', 'activeTaxes']),
    distributorPurchaseOrders() {
      return this.orders.filter(m => this.distributor?.id === (m.operation.type === 'distributor_id' && m.operation.value))
    },
    purchaseOrderColumns() {
      const columns = getPurchaseOrdersColumns(this.translations, this.distributorsById, this.setGlobalAction)
        .filter(col => !['more'].includes(col.colId))

      columns.push({
        colId: 'selectButton',
        pinned: 'right',
        cellClass: ['cell-more-details'],
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          title: this.translations.txtGenericSelect,
          buttonVariant: 'primary',
          hideIcon: true,
          onClick: id => {
            this.handleLinkingPurchaseOrder(id)
          }
        },
        valueGetter: (params) => ({
          id: params.data.id
        })
      })

      return columns
    },
    isLocked() {
      return this.movement?.date && this.venue.last_locked_inventory_date && (new Date(this.movement.date) < new Date(this.venue.last_locked_inventory_date))
    },
    extraUnitsOfMeasurement() {
      let arr = [{ id: 'unit', short: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]

      if (this.selectedLine) {
        let item = prepareVariantIdAsItem(this.selectedLine.item_distributor_id, this.$store.state)
        if (item && item.case_size && item.case_size > 1) {
          arr.push({ id: 'case', title: 'Case', units_of_measurements: [{ id: 'case', short: 'case', title: 'Case', type: 'case' }] })
        }
      }

      return arr
    },
    linkedDistributors() {
      return this.activeDistributors.filter(d => d && d.link_with_venue_id)
    },
    validations() {
      return {
        totalsMatch: {
          type: 'totalsMatch',
          message: this.translations.txtMovementsValidationTotalsMatch,
          validator: (newVal) => {
            const roundedNewVal = Math.round(newVal * 100) / 100
            const computedTotalRounded = Math.round(this.computedTotal * 100) / 100
            let valid = !newVal || Math.abs(computedTotalRounded - roundedNewVal) <= 0.5

            return valid
          }
        }
      }
    },
    addDistributorConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
    adjustmentFieldValues() {
      if (this.movement.adjustments && this.movement.adjustments.length) {
        return valuesToObjectById(this.movement.adjustments.map(z => ({ id: z.id, value: z.value.value })))
      }
      return {}
    },
    pageActions() {
      if (this.movement) {
        let actions = [
          { key: 1, title: this.translations.txtMovementsDownloadWithHeader, action: this.exportExcel },
          { key: 2, title: this.translations.txtGenericSendEmail, action: this.sendEmail },
          { key: 3, title: this.translations.txtMovementExtraLineReasons, action: this.showExtraLineReasons },
          { key: 5, title: this.translations.txtMovementAdjustmentReasons, action: this.showAdjustmentReasons },
          { key: 4, title: this.translations.txtGenericClone, action: this.clone },
          { key: 7, title: this.translations.txtCustomFieldsView, action: this.openCustomFields },
          { key: 20, title: this.translations.txtGLAccountsManage, action: this.glAccounts, hide: !this.venue.accounting_enabled }
        ]

        if (this.distributor) {
          actions.push({ key: 5, title: this.translations.txtDistributorManage, action: this.editDistributor })
        }

        if (this.currentPermissionsByType.invoice_send_to_accounting) {
          if (this.venue?.accounting_integration?.status === 'active' && this.venue?.accounting_integration?.type === 'quickbooks') {
            actions.push({
              key: 333,
              title: this.movement.sent_to_accounting ? this.translations.txtMovementAccountingResend : this.translations.txtMovementAccountingSend,
              action: this.sendToAccounting
            })
          }

          if (this.venue?.accounting_integration?.type === 'quickbooks_desktop') {
            actions.push({
              key: 335,
              action: () => {
                if (this.$refs.confirmDialog) {
                  this.$refs.confirmDialog.confirm({
                    callback: () => {
                      api.movementsQuickbooksDesktop(this.movement.id).then(() => {
                        this.getMovementData()
                      })
                    },
                    autoConfirm: this.movement?.sent_to_accounting,
                    message: this.translations.txtMovementAccountingQuickbooksDesktopExplain,
                    title: this.translations.txtMovementAccountingQuickbooksDesktop
                  })
                }

              },
              title: this.translations.txtMovementAccountingQuickbooksDesktop
            })
          }
        }

        if (this.isIntake && this.currentPermissionsByType.purchase_order_receive_from) {
          actions.push({ key: 50, title: this.translations.txtMovementLinkToPurchaseOrder, action: () => { this.linkPurchaseOrderModal = true }, hide: !this.isIntake || (this.isIntake && this.movement.purchase_order) })
        }

        if (this.currentPermissionsByType.invoice_archive) {
          actions.push({ key: 110, isDivider: true })
          actions.push({ key: 11, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.movement.archived, action: this.archive })
          actions.push({ key: 12, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.movement.archived, action: this.restore })
        }
        return actions
      }
      return []
    },
    activeLocations() {
      return this.locations.filter(d => d && !d.archived)
    },
    addNewReason() {
      return {
        action: () => {
          this.setGlobalAction({ type: 'movementAdjustmentReasonEdit', action: { id: 0, onChange: reason => this.onAdjustmentReasonSelected(reason.id) } })
        },
        label: this.translations.txtMovementAdjustmentReasonNew
      }
    },
    disabled() {
      return (this.isTransferOut && this.movement.sent) || this.isLocked
    },
    itemsGridColumns() {
      return getItemsGridColumns({
        venue: this.venue,
        currentPermissionsByType: this.currentPermissionsByType,
        disabled: this.disabled,
        setGlobalAction: this.setGlobalAction,
        glAccountsById: this.glAccountsById,
        activeGLAccounts: this.activeGLAccounts,
        accountingVenueClasses: this.accountingVenueClasses,
        translations: this.translations,
        openPriceEditor: this.openPriceEditor,
        save: this.save,
        activeTaxes: this.activeTaxes,
        taxesById: this.taxesById,
        openMeasurement: this.openMeasurementFromGrid,
        movementType: this.movementType,
        posItemsById: this.posItemsById,
        subrecipesById: this.subrecipesById
      })
    },
    gridOptions() {
      return {
        autoGroupColumnDef: {
          cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
          cellRendererParams: {
            wiskGroupActions: [
              {
                action: this.deleteMovementLine, title: this.translations.txtGenericRemove, variant: 'danger', available: (id, params) => (params.value?.source?.type === 'pos_item' || params.value?.source?.type === 'subrecipe') && this.isAdjustment
              },
            ]
          },
        }
      }
    },
    computedTotal() {
      const reducer = (accumulator, currentValue) => accumulator + currentValue.movement_info.total,
        extraLines = (this.movement.extra_lines || []).map(a => ({ movement_info: { total: a.total || (a.price_per_unit || a.price || 0) * (a.quantity || 0) } }))

      let total = [...extraLines, ...this.items].reduce(reducer, 0)
      return round(total, 2)
    },
    modalTitle() {
      let title = ''
      if (this.movement) {
        title = `${this.translations.txtGenericNewMovement}: ${this.translations[this.movement.type]}`
        if (this.movement.id && this.movement.description) {
          title = this.movement.description
          if (this.movement.archived) {
            title += ` - (${this.translations.txtGenericArchived})`
          }
        }
      }

      return title
    },
    movementType() {
      if (this.movement && this.movement.id && this.movement.operation) {
        return this.movement.operation.type
      }
      return (this.editAction && this.editAction.type) || ''
    },
    isIntake() {
      return this.movementType === 'intake'
    },
    isReturn() {
      return this.movementType === 'return'
    },
    isTransfer() {
      return this.movementType === 'transfer'
    },
    isAdjustment() {
      return this.movementType === 'adjustment'
    },
    isTransferOut() {
      return this.movementType === 'transfer_out'
    },
    distributor() {
      return (this.operationOperation?.distributor_id && this.distributorsById[this.operationOperation.distributor_id]) || undefined
    },
    distributorName() {
      return this.distributor?.title
    },
    excelExportHeader() {
      let header = [],
        titles = [this.translations.txtGenericDate],
        values = [formatDate(this.movement.date)]

      if (this.isReturn || this.isIntake || this.isTransferOut) {
        titles.push(this.translations.txtGenericDistributor)
        values.push(this.distributorName)
      }
      if (this.isAdjustment) {
        titles.push(this.translations.txtGenericLocation)
        values.push(this.getLocationTitle(this.operationOperation.at))
      }
      if (this.isReturn || this.isTransferOut) {
        titles.push(this.translations.txtGenericLocation)
        values.push(this.getLocationTitle(this.operationOperation.from))
      }
      if (this.isIntake) {
        titles.push(this.translations.txtGenericLocation)
        values.push(this.getLocationTitle(this.operationOperation.to))

        titles.push(this.translations.txtMovementEditInvoiceNumber)
        values.push(this.operationOperation.invoice_number || '')

        titles.push(this.translations.txtMovementEditInvoiceTotalPaper)
        values.push({ type: 'Number', value: this.operationOperation.invoice_total || 0, styleId: 'currency' })
      }

      if (this.isTransfer) {
        titles.push(this.translations.txtMovementEditLocationFrom)
        values.push(this.getLocationTitle(this.operationOperation.from))

        titles.push(this.translations.txtMovementEditLocationTo)
        values.push(this.getLocationTitle(this.operationOperation.to))
      }

      titles.push(this.translations.txtGenericTotal)
      values.push({ type: 'Number', value: this.computedTotal || 0, styleId: 'currency' })

      header[0] = titles
      header[1] = values
      header[2] = []
      return header
    },
    costChangesCount() {
      const costChangesItems = this.movement.items.filter(i => i.change_per_unit || (i.price_per_unit && i.previous_cost_per_unit && i.price_per_unit !== i.previous_cost_per_unit))
      return costChangesItems.length
    }
  },
  methods: {
    ...mapActions(['setMovement', 'getMovements', 'setGlobalAction', 'updateVenue', 'setWindowResizeKey', 'notify']),
    handleLinkingPurchaseOrder(id) {
      this.$refs.confirmDialog.confirm({
        callback: () => {
          this.save({ type: 'purchase_order_id', value: id })
        },
        message: this.translations.confirmMovementEditLinkToPurchaseOrder,
        title: this.translations.txtMovementLinkToPurchaseOrder
      })
    },
    gridReady(agGridApi) {
      this.gridApi = agGridApi
    },
    taxSchemeSelectorLabelTranslator(item) {
      return this.translations.groupTaxSchemes[item.id]
    },
    glAccounts() {
      this.setGlobalAction({ type: 'glAccounts', action: {} })
    },
    sendToAccounting() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.$refs.secondConfirmDialog.confirm({
              callback: () => {
                this.save({ type: 'send_to_accounting' }, true)
              },
              message: this.translations.txtMovementAccountingResendConfirmation,
              title: this.translations.txtMovementAccountingSentAlready,
              autoConfirm: !this.movement.sent_to_accounting
            })
          },
          message: this.translations.txtMovementAccountingSendConfirmation,
          title: this.translations.txtMovementAccountingSend
        })
      }
    },
    openMeasurementFromGrid(id, data) {
      let found = this.movement.items.find(m => m.id === id && m.sort_order === data.sortOrder)//line_id

      this.selectedLineMeasurement = (found && found.measurements && found.measurements[0]) || null
      this.selectedLine = found || null

      if (this.selectedLineMeasurement) {
        this.selectedLineMeasurement.item_measurement_type = found && found.item_measurement && found.item_measurement.type
        this.selectedLineMeasurement.unit_of_measurement = this.selectedLineMeasurement.unit_of_measurement || this.selectedLineMeasurement.type
      }
    },
    openPriceEditor(id, data) {
      let found = this.movement.items.find(m => m.id === id && m.sort_order === data.sortOrder)
      this.selectedLine = found || null
      this.selectedLinePrice = (this.selectedLine && this.selectedLine.price) || null
    },
    onMeasurementChange(payload) {
      if (this.selectedLine && payload) {
        let item = prepareVariantIdAsItem(this.selectedLine.item_distributor_id, this.$store.state) || {}

        if (payload.unit_of_measurement === 'case' || payload.unit_of_measurement === 'unit') {
          payload.type = payload.unit_of_measurement
        } else {
          payload.type = 'manual'
        }
        this.save({ type: 'item_update', value: { ...this.selectedLine, measurements: [{ ...payload, case_size: item.case_size }] } }) //not item case size but item variant case size
      }
    },
    onPriceChange(price) {
      if (this.selectedLine && price) {
        this.save({ type: 'item_update', value: { ...this.selectedLine, price } })
      }
    },
    onAdjustmentReasonSelected(reasonId) {
      this.newMovement.adjustment_reason_id = reasonId
      this.operationOperation.adjustment_reason_id = reasonId

      if (this.movement && this.movement.id) {
        this.save({ type: 'operation', value: this.operationOperation })
      }
    },
    getLocation(location) {
      if (location) {
        return this.locationsById[location.id] || { id: location.id, title: this.translations.txtGenericNotFound }
      }
      return null
    },
    getLocationTitle(location) {
      if (location) {
        let found = this.locationsById[location.id] || { id: location.id, title: this.translations.txtGenericNotFound }
        return found.title
      }
      return ' '
    },
    saveExtraLine() {
      if (this.selectedExtraLine) {
        this.save({ type: 'extra_line_update', value: this.selectedExtraLine })
      }
    },
    exportExcel() {
      let params = {
        header: this.excelExportHeader
      }

      if (this.$refs.wiskItemsGrid && this.$refs.wiskItemsGrid.exportExcel) {
        this.$refs.wiskItemsGrid.exportExcel(params)
      }
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'movement' } })
    },
    removeImage(url) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save(
              [
                { type: 'attachment_delete', value: { url } },
                { type: 'photo_delete', value: { url } }
              ],
              true
            )
          },
          message: this.translations.confirmRemoveText,
          title: this.translations.confirmRemoveTitle
        })
      }
    },
    editDistributor() {
      this.setGlobalAction({ type: 'distributorEdit', action: { id: this.distributor.id }, preventMultiple: true })
    },
    addImages() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          multiple: true,
          fileTypesAccept: 'image/*, .pdf',
          save: urls => {
            this.save(
              urls.map(url => ({ type: 'attachment_add', value: { url } })),
              true
            )
          }
        }
      })
    },
    addImagesToNewMovement() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          multiple: true,
          fileTypesAccept: 'image/*, .pdf',
          save: urls => {
            this.newMovement.attachments = urls
            this.newMovement = { ...this.newMovement }
          }
        }
      })
    },
    clone() {
      if (this.loading || !this.movement.id) return
      this.loading = true
      api
        .cloneMovement(this.movement.id)
        .then(result => {
          this.getMovements()
          this.setGlobalAction({ type: 'movementEdit', action: { id: result.id } })

          this.notify({
            message: this.translations.txtMovementsClonedMessage,
            type: 'info'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveNew() {
      let operations = []
      if (this.newMovement.attachments) {
        operations = this.newMovement.attachments.map(url => ({ type: 'attachment_add', value: { url } }))
        delete this.newMovement.attachments
      }
      operations = [{ type: 'operation', value: this.newMovement }, { type: 'date', value: this.newMovement.date }, ...operations]
      this.save(operations)
    },
    saveDebounced(operations) {
      clearTimeout(this.saveDebounceTimeoutId)
      this.saveDebounceTimeoutId = setTimeout(() => {
        this.save(operations)
      }, 1000)
    },
    save(operations, noEmail, callback) {
      if (operations) {
        if (operations.operationTypes) {
          delete operations.operationTypes
        }

        this.movementCopy = this.movementCopy || merge({}, this.movement)

        this.setMovement({ id: this.movementCopy.id, operation: operations.operations || operations || this.operations })
          .then(result => {
            if (this.editAction) {
              this.getMovements()

              this.movement = merge({}, result)
              this.movementCopy = merge({}, result)
              this.populateItems()
              if (!noEmail) {
                this.changed = !!this.editAction.id
              }

              if (this.editAction.onChange) {
                this.editAction.onChange()
              }

              if (typeof callback === 'function') {
                callback(result)
              }

              if (!this.editAction.id) {
                this.setGlobalAction({ type: 'movementEdit', action: null })
                setTimeout(() => {
                  this.setGlobalAction({ type: 'movementEdit', action: { id: result.id, imagesSideBySide: !!(result && result.attachments && result.attachments.length) } })
                }, 0)
              }

              this.linkPurchaseOrderModal = false
            }
          }).catch(() => {
            this.operationOperation = merge({}, this.movement.operation)
            this.key++
          })
      }
    },
    setValidState(errorCount) {
      this.addMovementValid = !errorCount
    },
    showExtraLineReasons() {
      this.setGlobalAction({ type: 'movementExtraLineReasons', action: {} })
    },
    showAdjustmentReasons() {
      this.setGlobalAction({ type: 'movementAdjustmentReasons', action: {} })
    },
    archive() {
      this.save({ type: 'archive', value: true }, true)
    },
    restore() {
      this.save({ type: 'archive', value: false }, true)
    },
    deleteMovementLine(id, params) {
      if (this.$refs.confirmDialog) {
        const sourceType = params.value.source.type,
          sourceTitle = sourceType === 'pos_item' ? this.translations.txtGenericPOSItem : this.translations.txtSubrecipeTitle
        this.$refs.confirmDialog.confirm({
          callback: () => {
            const operation = {
              value: {
                order: params.value.source.extra.sort_order,
                sort_order: params.value.source.extra.sort_order
                }
            }
            if (sourceType === 'pos_item') {
              operation.type = 'pos_item_delete'
              operation.value.pos_item_id = id
            } else {
              operation.type = 'subrecipe_delete'
              operation.value.subrecipe_id = id
            }
            this.save(operation, true)
          },
          message: this.translations.translate('confirmMovementEditRemoveLineFromDepletionDescription', { '{a}': sourceTitle.toLowerCase() }),
          title: this.translations.translate('confirmMovementEditRemoveLineFromDepletion', { '{a}': sourceTitle.toLowerCase() }),
        })
      }
    },
    populateItems() {
      const sourcesById = {
        pos_item: arrayToObjectById(this.movement.pos_items, 'sort_order'),
        subrecipe: arrayToObjectById(this.movement.subrecipes, 'sort_order')
      }
      this.items = this.movement.items.map(movementInfo => {
        let item = prepareVariantIdAsItem(movementInfo.item_distributor_id, this.$store.state) || {}
        if (movementInfo?.source) {
          movementInfo.source.extra = sourcesById[movementInfo.source.type][movementInfo.source.line_id]
        }
        return merge({}, item, { movement_info: movementInfo }, { combined_id: movementInfo.item_distributor_id + '_' + movementInfo.sort_order })
      })
      this.items.sort((a, b) => compareNumbers(a.movement_info.sort_order, b.movement_info.sort_order))

      if (!this.initDone) {
        setTimeout(() => {
          let element = document.getElementById('movement-add-item-scroll-target')

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
          this.initDone = true
        }, 5000)
      }
    },
    openEditMode() {
      let id = this.editAction.id

      this.setGlobalAction({ type: 'movementEdit', action: null })

      setTimeout(() => {
        this.setGlobalAction({ type: 'movementEdit', action: { id } })
      }, 500)
    },
    emailPdf() {
      if (this.venue && this.venue.id) {
        api.emailPdf(`movements/${this.movement.id}/pdf`,
          // 'invoice.pdf'
        )
      }
    },
    sendEmail() {
      let type = get(this.translations, this.movementType, this.movementType).toLowerCase(),
        text = this.translations.translate('tplConfirmMovementEditSendEmail', {
          '{a}': type
        }),
        yes = this.translations.txtGenericYes,
        no = this.translations.txtGenericNo

      this.notify({
        text,
        buttons: [
          Noty.button(
            yes,
            'btn btn-primary btn-sm  mx-1',
            () => {
              this.save({ type: 'send_email' }, true)
              Noty.closeAll()
            },
            { id: 'button1', 'data-status': 'ok' }
          ),
          Noty.button(no, 'btn btn-secondary btn-sm  mx-1', () => {
            Noty.closeAll()
          })
        ],
        innerType: 'alert',
        timeout: 10000,
        type: 'makeToast'
      })
    },
    sendTransferOut() {
      if (this.$refs.confirmDialog) {
        let message = this.translations.translate('tplConfirmMovementEditSendTransferOut', {
          '{a}': this.getLocationTitle(this.operationOperation.from),
          '{b}': this.distributor.title
        })
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ type: 'send_transfer_out' }, true, () => {
              this.key++
            })
          },
          message,
          title: this.translations.confirmMovementEditSendTransferOutTitle,
          okText: this.translations.txtGenericSendTransferOut
        })
      }
    },
    getMovementData() {
      this.loading = true

      api.movementById(this.editAction.id).then(movement => {
        this.movement = movement
        this.movementCopy = merge({}, movement)

        this.populateItems()
        this.key++
        this.loading = false
      })
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.refInvoiceTotalComponent && this.$refs.refInvoiceTotalComponent.triggerValidationCheck) {
        this.$refs.refInvoiceTotalComponent.triggerValidationCheck()
      }
    }, 0)
  },
  beforeUnmount() {
    this.gridApi = null
    setTimeout(() => {
      if (this.changed) {
        this.sendEmail()
      }
    }, 1000)
  },
  watch: {
    imagesSideBySide() {
      this.setWindowResizeKey(new Date().getTime())

      setTimeout(() => {
        this.setWindowResizeKey(new Date().getTime())

        if (this.gridApi && this.gridApi.sizeColumnsToFit) {
          this.gridApi.sizeColumnsToFit()
        }
      }, 100)
    },
    editAction: {
      handler(editAction) {
        newMovement.date = new Date()

        this.newMovement = { ...newMovement }
        this.operationOperation = { ...newMovement }
        this.selectedEditorTab = 0
        this.addMovementValid = false

        if (editAction) {
          if (!(this.movement && this.movement.id)) {
            if (editAction.id) {
              this.getMovementData()

              setTimeout(() => {
                this.imageViewerVisible = !!this.editAction.photoMode
                this.imagesSideBySide = !!this.editAction.imagesSideBySide
              }, 100)
            } else {
              this.newMovement.type = editAction.type
              this.movement = editAction
              this.movementCopy = merge({}, editAction)

              if (this.newMovement.type === 'return') {
                this.newMovement.from = this.locations.find(l => l && l.is_default)
              }
              if (this.newMovement.type === 'intake') {
                this.newMovement.to = this.locations.find(l => l && l.is_default)
              }
            }
          }

          if (editAction.successMode) {
            setTimeout(() => {
              this.sendEmail()
            }, 500)
          }
        }

        if (editAction && editAction.operation) {
          this.operationOperation = merge({}, editAction.operation)
        }
      },
      immediate: true
    },
    movement(movement) {
      setTimeout(() => {
        if (this.$refs.refInvoiceTotalComponent && this.$refs.refInvoiceTotalComponent.triggerValidationCheck) {
          this.$refs.refInvoiceTotalComponent.triggerValidationCheck()
        }
      }, 500)
      if (movement && movement.operation) {
        this.operationOperation = merge({}, movement.operation)
      }

      this.movement.attachments = this.movement.attachments || []
      this.attachments = []
      this.movement.attachments.forEach(i => {
        if (!this.attachments.includes(i.url)) {
          this.attachments.push(i.url)
        }
      })
    },
    bottlesById() {
      this.populateItems()
    }
  }
}
</script>

<style lang="scss">
#movement-edit-container-image-side-by-side {
  max-height: calc(100vh - 90px);
}
</style>
